// src/pages/FormPage.jsx

import React, { useEffect, useState } from "react";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import fetcher_data from "../data_fetcher";
import courier_fetch from "../courier_fetcher";
import broker_fetch from "../broker_fetcher";

import { toast } from "react-toastify";
import axios from "axios";
import { FiMenu, FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";

export default function FormPage({ app }) {
  const [selectedData, setSelectedData] = useState(
    app === "banking"
      ? fetcher_data.writes[0]
      : app === "courier"
        ? courier_fetch.writes[0]
        : app === "broker"
          ? broker_fetch.writes[0]
          : {}
  );
  const [formData, setFormData] = useState({});
  const [requiredData, setRequiredData] = useState(selectedData.requiredData || []);
  const [reload, setReload] = useState(1);

  const [isCourierEditMode, setIsCourierEditMode] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(localStorage.getItem("sidebarOpen") === "true");
  const [isHovering, setIsHovering] = useState(false);

  const writes =
    app === "banking"
      ? fetcher_data.writes
      : app === "courier"
        ? courier_fetch.writes
        : app === "broker"
          ? broker_fetch.writes
          : [];
  const dropdownItems = writes.map((item) => item.name);

  // Handler Functions
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: Number(value) }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prevData) => ({ ...prevData, [name]: date }));
  };

  // Fetching Functions
  async function fetchExisting_courier() {
    if (app === "courier" && selectedData.name === "create_shipment") {
      if (!formData.tracking_id || formData.tracking_id.length < 2) {
        return;
      }

      let fetch_shipments = courier_fetch.reads.find(
        (item) => item.name === "shipments"
      );
      let path = fetch_shipments.path;
      try {
        let data = await courier_fetch.getData(
          path,
          fetch_shipments.formatter_function
        );

        if (data === "Unauthorized") {
          toast.error("Unauthorized to fetch data");
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/login";
          }, 2000);
          return;
        }
        let tracking_id = formData.tracking_id;
        let existing_data = data?.find((item) => item.tracking_id === tracking_id);

        if (!existing_data) {
          setIsCourierEditMode(false);
          return;
        }

        console.log(existing_data);

        setSelectedData({
          ...selectedData,
          path: "/edit",
        });

        setIsCourierEditMode(true);
        existing_data["updating"] = true;
        existing_data["updates"] = null;

        delete existing_data["updates"];
        setFormData(existing_data);

        return data;
      } catch (error) {
        console.error("Error fetching courier data:", error);
        toast.error("Failed to fetch courier data.");
      }
    }
  }

  async function fetchExisting_user() {
    if (app === "banking" && selectedData.name === "Edit Customer") {
      if (!formData.user_id || formData.user_id.length < 2) {
        return;
      }
      let fetcher = fetcher_data.reads.find(
        (item) => item.name === "customers"
      );
      let path = fetcher.path;
      try {
        let data = await fetcher_data.getData(
          path,
          fetcher.formatter_function
        );

        if (data === "Unauthorized") {
          toast.error("Unauthorized to fetch data");
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/login";
          }, 2000);
          return;
        }
        let user_id = formData.user_id;
        let existing_data = data?.find((item) => item.user_id === user_id);

        if (!existing_data) {
          return;
        }

        console.log(existing_data);

        setSelectedData({
          ...selectedData,
          path: "/admin/edit_customer",
        });

        existing_data["is_active"] = false;
        existing_data["is_pending_suspension_on_tx"] = false;
        existing_data["is_transfer_blocked"] = false;

        setFormData(existing_data);
        return data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to fetch user data.");
      }
    }
  }

  async function fetch_existing_courier_config() {
    if (app === "courier" && selectedData.name === "site settings") {
      let api = process.env.REACT_APP_COURIER_API;

      try {
        let { data } = await axios.get(`${api}/config`, {
          headers: {
            "x-tenant-id": `${localStorage.getItem("tenantId")}`,
          },
        });

        setFormData(data);

        console.log(data);
      } catch (error) {
        console.error("Error fetching courier config:", error);
        toast.error("Failed to fetch courier configuration.");
      }
    }
  }

  async function fetch_existing_config() {

    if (app === "banking" && selectedData.name == "Site settings") {
      toast.info("Fetching existing data...", {});
      let api = process.env.REACT_APP_BANKING_API;

      try {
        let { data } = await axios.get(`${api}/config`, {
          headers: {
            "x-tenant-id": `${localStorage.getItem("tenantId")}`,
          },
        });

        setFormData(data);

        console.log(data);
      } catch (error) {
        console.error("Error fetching banking config:", error);
        toast.error("Failed to fetch banking configuration.");
      }
    }
  }

  // useEffect Hooks
  useEffect(() => {
    fetch_existing_courier_config();
    fetch_existing_config();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  useEffect(() => {
    fetchExisting_courier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.tracking_id]);

  useEffect(() => {
    fetchExisting_user();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.user_id]);

  // Submit Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.loading("Submitting data...", {
      theme: "dark",
      position: "bottom-center",
    });
    console.log(formData); // Here you can perform your desired actions with the form data
    let post =
      app === "banking"
        ? await fetcher_data?.postData(selectedData.path, formData)
        : app === "courier"
          ? await courier_fetch?.postData(selectedData.path, formData)
          : app === "broker"
            ? await broker_fetch?.postData(selectedData.path, formData)
            : null;

    if (
      parseInt(post.status) !== 200 &&
      parseInt(post.status) !== 201
    ) {
      console.log("-----------");
      toast.error(`${selectedData.name} response: ${post.data.toString()}`, {
        theme: "dark",
        position: "bottom-center",
      });
    } else {
      console.log("++++++++++");
      toast.dismiss();
      if (post.data.message) {
        toast.success(`${selectedData.name} response: ${post.data.message}`, {
          theme: "dark",
          position: "bottom-center",
        });
        return;
      }

      if (post.data.data) {
        toast.success(`${selectedData.name} response: ${post.data.data}`, {
          theme: "dark",
          position: "bottom-center",
        });
        return;
      }

      if (post.data) {
        toast.success(`${selectedData.name} response: ${post.data}`, {
          theme: "dark",
          position: "bottom-center",
        });
        return;
      } else {
        toast.success(`${selectedData.name}`, {
          theme: "dark",
          position: "bottom-center",
        });
      }
    }
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };

  // Sidebar Toggle Functions
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    localStorage.setItem("sidebarOpen", !isSidebarOpen);
  };

  const handleItemClick = (item) => {
    setFormData({});
    setSelectedData(item);
    setRequiredData(item.requiredData || []);
    setReload(reload + 1);
    setIsSidebarOpen(false); // Auto-collapse sidebar on selection
  };

  return (
    <div className="w-full h-screen flex bg-gray-800 text-white">
      {/* Desktop Sidebar */}
      <div
        className={`${isSidebarOpen || isHovering ? "w-64" : "w-16"
          } bg-gray-900 text-white flex flex-col transition-all duration-300
        hidden md:flex
        relative`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        aria-label="Sidebar"
      >
        <div className="flex items-center justify-between p-4">
          {(isSidebarOpen || isHovering) && (
            <h2 className="text-lg font-semibold">Actions</h2>
          )}
          <button
            onClick={toggleSidebar}
            className="focus:outline-none"
            aria-label={isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
          >
            {isSidebarOpen ? (
              <FiChevronLeft size={24} />
            ) : (
              <FiChevronRight size={24} />
            )}
          </button>
        </div>
        <nav className="flex-1 overflow-y-auto">
          <ul className="p-2 space-y-1">
            {writes.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => handleItemClick(item)}
                  className={`w-full text-left px-4 py-2 rounded hover:bg-gray-700 ${selectedData.name === item.name
                    ? "bg-gray-700 font-semibold"
                    : ""
                    } flex items-center transition-colors duration-200`}
                  aria-current={
                    selectedData.name === item.name ? "page" : undefined
                  }
                >
                  {(isSidebarOpen || isHovering) ? (
                    <span>{item.name}</span>
                  ) : (
                    <span className="mx-auto" title={item.name}>
                      {item.name.charAt(0)}
                    </span>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 md:hidden">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={toggleSidebar}
            aria-hidden="true"
          ></div>
          {/* Sidebar */}
          <div
            className="fixed inset-y-0 left-0 w-64 bg-gray-900 text-white flex flex-col"
            aria-label="Mobile Sidebar"
          >
            <div className="flex items-center justify-between p-4">
              <h2 className="text-lg font-semibold">Actions</h2>
              <button
                onClick={toggleSidebar}
                className="focus:outline-none"
                aria-label="Close sidebar"
              >
                <FiX size={24} />
              </button>
            </div>
            <nav className="flex-1 overflow-y-auto">
              <ul className="p-2 space-y-1">
                {writes.map((item, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleItemClick(item)}
                      className={`w-full text-left px-4 py-2 rounded hover:bg-gray-700 ${selectedData.name === item.name
                        ? "bg-gray-700 font-semibold"
                        : ""
                        } flex items-center transition-colors duration-200`}
                      aria-current={
                        selectedData.name === item.name ? "page" : undefined
                      }
                    >
                      <span>{item.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-y-auto">
        {/* Mobile Toggle Button */}
        <div className="md:hidden mb-4">
          <button
            onClick={toggleSidebar}
            className="focus:outline-none"
            aria-label="Open sidebar"
          >
            <FiMenu size={24} />
          </button>
        </div>

        {/* Form Container */}
        <div className="relative">
          <div className="max-w-2xl mx-auto bg-gray-900 p-8 rounded-lg shadow-lg">
            <div className="mb-6">
              <h1 className="text-3xl font-bold mb-2">{selectedData.name}</h1>
              <p className="text-gray-400">
                {app === "courier" && isCourierEditMode
                  ? "You are editing a shipment"
                  : ""}
                {app === "banking" && selectedData.name === "Edit Customer"
                  ? "Edit customer details"
                  : ""}
              </p>
            </div>
            <form
              id="form-id"
              className="grid gap-6"
              onSubmit={handleSubmit}
            >
              {requiredData.map((field, index) => (
                <div key={index} className="flex flex-col">
                  <Label htmlFor={field.name} className="text-white">
                    {field.name}
                  </Label>
                  {field.type === "string" && (
                    <Input
                      id={field.name}
                      name={field.name}
                      type="text"
                      placeholder={field.name}
                      value={formData[field.name] || ""}
                      onChange={handleInputChange}
                      className="bg-gray-800 text-white placeholder-gray-500 mt-1"
                      required={field.is_required}
                    />
                  )}
                  {field.type === "number" && (
                    <Input
                      id={field.name}
                      name={field.name}
                      type="number"
                      placeholder={field.name}
                      value={formData[field.name] || ""}
                      onChange={handleNumberInputChange}
                      className="bg-gray-800 text-white placeholder-gray-500 mt-1"
                      required={field.is_required}
                    />
                  )}
                  {field.type === "dropdown" && (
                    <Select
                      value={formData[field.name] || ""}
                      onValueChange={(value) =>
                        handleSelectChange(field.name, value)
                      }
                      className="mt-1"
                    >
                      <SelectTrigger className="bg-gray-800 text-white">
                        <SelectValue placeholder={`Select ${field.name}`} />
                      </SelectTrigger>
                      <SelectContent className="bg-gray-800 text-white">
                        {field.options.map((option, idx) => (
                          <SelectItem value={option} key={idx}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                  {field.type === "boolean" && (
                    <div className="flex items-center gap-2 mt-2">
                      <input
                        id={field.name}
                        name={field.name}
                        type="checkbox"
                        checked={formData[field.name] || false}
                        onChange={handleCheckboxChange}
                        className="form-checkbox bg-gray-800 border-gray-600 text-white"
                      />
                      <Label htmlFor={field.name} className="text-white">
                        {field.name}
                      </Label>
                    </div>
                  )}
                  {field.type === "date" && (
                    <DatePicker
                      id={field.name}
                      selected={formData[field.name] || null}
                      onChange={(date) => handleDateChange(field.name, date)}
                      showTimeSelect
                      dateFormat="Pp"
                      className="w-full border rounded px-3 py-2 bg-gray-800 text-white placeholder-gray-500 mt-1"
                      placeholderText={field.name}
                      required={field.is_required}
                    />
                  )}
                </div>
              ))}

              {/* Submit Button for Desktop */}
              <Button
                type="submit"
                className="hidden md:block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded focus:outline-none transition-colors"
                aria-label="Submit form"
              >
                Submit
              </Button>
            </form>
          </div>

          {/* Sticky Submit Button for Mobile */}
          <div className="md:hidden fixed bottom-0 left-0 w-full bg-gray-900 bg-opacity-90 p-4 z-30">
            <Button
              type="submit"
              form="form-id"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded focus:outline-none transition-colors"
              aria-label="Submit form"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
